@font-face {
font-family: '__manrope_24cc0a';
src: url(/_next/static/media/afc09b6565507a01-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__manrope_24cc0a';
src: url(/_next/static/media/d9e04eab685e3508-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__manrope_24cc0a';
src: url(/_next/static/media/2ca4d35538b789b5-s.p.woff) format('woff');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__manrope_24cc0a';
src: url(/_next/static/media/496572d7033f2028-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__manrope_24cc0a';
src: url(/_next/static/media/116ae23d43ba33a3-s.p.woff) format('woff');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__manrope_Fallback_24cc0a';src: local("Arial");ascent-override: 96.06%;descent-override: 28.25%;line-gap-override: 0.00%;size-adjust: 110.61%
}.__className_24cc0a {font-family: '__manrope_24cc0a', '__manrope_Fallback_24cc0a'
}.__variable_24cc0a {--font-manrope: '__manrope_24cc0a', '__manrope_Fallback_24cc0a'
}

